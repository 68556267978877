/* Your provided CSS code here */
body {
    padding: 0;
    margin: 0;
  }
  
  main {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    line-height: 1.33em;
    color: rgba(255, 255, 255, 0.88);
    background-color: #f1f1f1;
    min-height: 100vh;
  }
  
  main {
    background-image: linear-gradient(
        to bottom,
        rgb(226 229 237 / 96%) 10%,
        hsl(33.23deg 14% 86% / 78%) 100%
      ),
      url(https://www.faroeislands.io/visuals/bg/grass-texture.jpg);
  }
  
  section {
    margin: auto;
    padding: 3vw;
    padding-bottom: 3rem;
  }
  
  .info {
    background-color: #750015;
    margin: 0;
  }
  
  .info span::first-letter {
    font-size: 180%;
    font-family: serif;
  }
  .go-masonry .go_gridItem {
     flex-grow: 0 !important;
  }
  .go-masonry {
    justify-content: center !important;
}
  
  .heading {
    margin: 0;
    background-color: rgb(47, 54, 63);
    text-align: center;
  }
  
  .heading h1 {
    font-size: 222%;
    color: rgb(255, 255, 255);
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: normal;
  }
  
  .heading p {
    color: white;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.88);
  }
  
  .gridOverflow.go-masonry {
    --gridGap: 1rem;
    --itemMinWidth: 30%;
    --itemRounding: 25% 88% 10% 88% / 18% 9px 15% 9px;
    --linkActionIcon: "»";
    --masonryItemHeight: 15rem;
  }
  
  @media (max-width: 600px) {
    .gridOverflow.go-masonry {
      --itemMinWidth: 45%;
      --masonryItemHeight: 29vw;
    }
  }
.go_gridItem {
  z-index: 10; /* Lower than the buttons and modal */
}

.absolute {
  z-index: 20; /* Higher than the gallery items but lower than the modal */
}
